import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "navigation__logo" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "shortName"
}
const _hoisted_4 = ["src"]


  import menuUl from "@/components/menuUl.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'MenuSide',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["content", { 'short-content': !_ctx.isOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: { name: 'home' } }, {
        default: _withCtx(() => [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("b", _hoisted_2, " SEO "))
            : (_openBlock(), _createElementBlock("b", _hoisted_3, " S "))
        ]),
        _: 1
      }),
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require(`@/assets/icons/arrowBackIcon.svg`),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createVNode(menuUl, { "is-open": _ctx.isOpen }, null, 8, ["is-open"])
  ], 2))
}
}

})