import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container__view" }
const _hoisted_3 = { class: "main" }

import MenuTop from "@/widgets/MenuTop.vue";
  import MenuSide from "@/widgets/MenuSide.vue";
  import { ref } from "vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

  const isMenuOpen = ref(true);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MenuSide, {
      class: "asside",
      isOpen: isMenuOpen.value,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (isMenuOpen.value = false))
    }, null, 8, ["isOpen"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MenuTop, {
        class: "main-header",
        isOpen: isMenuOpen.value,
        onOpen: _cache[1] || (_cache[1] = ($event: any) => (isMenuOpen.value = true))
      }, null, 8, ["isOpen"]),
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}
}

})