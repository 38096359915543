import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { PropType, ref, watchEffect } from "vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'contextMenu',
  props: {
    display: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    top: {
      type: Number as PropType<number>,
      required: true,
    },
    left: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: ["close"],
  setup(__props, { emit: __emit }) {

  const emit = __emit;
  const context = ref();
  const X = ref();
  const Y = ref();

  const props = __props;

  function close() {
    emit("close");
  }
  watchEffect(() => {
    if (context.value) {
      context.value.focus();
      if (props.left !== 0 && props.top !== 0) {
        const menuRect = context.value.getBoundingClientRect();
        if (menuRect) {
          const viewportHeight = window.innerHeight;
          if (props.top + menuRect.height > viewportHeight) {
            Y.value = menuRect.height - props.top;
          } else {
            Y.value = props.top;
          }
          const viewportWidth = window.innerWidth;

          if (props.left + menuRect.width > viewportWidth) {
            X.value = viewportWidth - menuRect.width;
          } else {
            X.value = props.left;
          }
        }
      }
    }
  });

return (_ctx: any,_cache: any) => {
  return (__props.display)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "context-menu",
        ref_key: "context",
        ref: context,
        style: _normalizeStyle({ top: Y.value + 'px', left: X.value + 'px' }),
        tabindex: "0",
        onBlur: _cache[0] || (_cache[0] = ($event: any) => (close()))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 36))
    : _createCommentVNode("", true)
}
}

})